import React from "react";
import App from "../components/App";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Main from "../components/Main";
import Teaser from "../components/Teaser";
import Pagination from "../components/Pagination";
import SEO from "../components/SEO";

function Home(props) {
  const { pageContext: { posts, pagination }, location } = props;

  return (
    <App>
      <Header />
      <SEO page={{ pathname: location.pathname, pageNumber: pagination.current }} />
      <Main>
        {posts.map(post => <Teaser key={post.id} post={post} />)}
        <Pagination {...pagination} />
      </Main>
      <Footer />
    </App>
  );
}

export default Home;
