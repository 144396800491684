import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { homePath } from "../utils/paths";


function Pagination(props) {
  const { current, totalPages } = props;
  const prevPath = homePath(current - 1);
  const nextPath = homePath(current + 1);

  return (
    <Wrapper>
      {current > 1 && (
        <>
          <PaginationLink to={prevPath}>Prev</PaginationLink>
          <Divider>-</Divider>
        </>
      )}
      <span>Page: {current} of {totalPages}</span>
      {current !== totalPages && (
        <>
          <Divider>-</Divider>
          <PaginationLink to={nextPath}>Next</PaginationLink>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  color: #717171;
  font-size: 18px;
  font-family: ${props => props.theme.headerFontFamily};
  margin-top: 50px;
  text-align: center;
  & > * {
    display: inline-block;
  }
`;

const Divider = styled.div`
  margin: 0 20px;
`;

const PaginationLink = styled(Link)`
  color: #717171;
  text-shadow: none;
  background-image: none;
`;

export default Pagination;
