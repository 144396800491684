import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Img from "gatsby-image";
import MetaText from "./MetaText";
import CategoriesList from "./CategoriesList";
import { formatDate } from "../utils/formats";
import { withSlug } from "../utils/withSlug";
import { postPath } from "../utils/paths";

function Teaser(props) {
  const { post } = props;
  const categories = post.frontmatter.categories.split(" ").map(withSlug);
  const { hero } = post.frontmatter;
  const path = postPath(post.fields.slug);

  return (
    <Article>
      <ArticleThumbnailLink to={path}>
        {hero && <Img fluid={hero.childImageSharp.fluid} />}
      </ArticleThumbnailLink>
      <ArticleTeaser>
        <ArticleDate>{formatDate(post.frontmatter.date)}</ArticleDate>
        <ArticleTitleLink to={path}>
          <ArticleTitle>{post.frontmatter.title}</ArticleTitle>
        </ArticleTitleLink>
        <ArticleExcerpt>
          {post.excerpt}{" "}
          <Link to={path}>read more</Link>
        </ArticleExcerpt>
        <ArticleCategories><CategoriesList categories={categories} /></ArticleCategories>
      </ArticleTeaser>
    </Article>
  );
}

const Article = styled.article`
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  margin-top: 40px;
  box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.16);
  @media (max-width: 580px) {
    grid-template-columns: auto;
  }
`;

const ArticleThumbnailLink = styled(Link)`
  grid-column: 1 / 9;
  display: block;
  text-shadow: none;
  background-image: none;

  .gatsby-image-wrapper {
    height: 100%;
  }

  @media (max-width: 580px) {
    grid-column: none;
    height: 200px;
  }
`;

const ArticleTeaser = styled.div`
  grid-column: 9 / 21;
  padding: 0 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 580px) {
    grid-column: none;
  }
`;

const ArticleDate = styled(MetaText)`
  margin: 20px 0 0;
`;

const ArticleTitleLink = styled(Link)`
  display: block;
  margin: 10px 0 0;
  text-shadow: none;
  background-image: none;
`;

const ArticleTitle = styled.h1`
  margin: 0;
  font-size: 1.41rem; /* 24px */
  line-height: 1.3;
`;

const ArticleExcerpt = styled.p`
  margin: 14px 0;
  line-height: 1.5;
`;

const ArticleCategories = styled(MetaText)`
  margin: auto 0 20px;
`;

export default Teaser;
